/* 常规中文 */
@font-face {
  font-family: 'CHINESE-HYQIHEI-60S';
  src: url('@/assets/font/Chinese-HYQiHei-60S.otf');
}

/* 常规英文-regular */
@font-face {
  font-family: 'ENGLISH-SFPROTEXT-REGULAR';
  src: url('@/assets/font/English-SF-Pro-Text-Regular.woff2');
}

/* 常规英文-bold */
@font-face {
  font-family: 'ENGLISH-SFPROTEXT-BOLD';
  src: url('@/assets/font/English-SF-Pro-Text-Heavy.woff2');
}

/* 特殊英文-regular */
@font-face {
  font-family: 'ENGLISH-SPECIAL-CLASHDISPLAY-REGULAR';
  src: url('@/assets/font/English-special-ClashDisplay-Medium.otf');
}

/* 特殊英文-bold */
@font-face {
  font-family: 'ENGLISH-SPECIAL-CLASHDISPLAY-BOLD';
  src: url('@/assets/font/English-special-ClashDisplay-Semibold.otf');
}

/* 特殊数字-regular */
@font-face {
  font-family: 'NUMBER-SPECIAL-BIORHYME-REGULAR';
  src: url('@/assets/font/Number-special-BioRhyme-Regular.ttf');
}

/* 特殊数字-bold */
@font-face {
  font-family: 'NUMBER-SPECIAL-BIORHYME-BOLD';
  src: url('@/assets/font/Number-special-BioRhyme-ExtraBold.ttf');
}